import Vue from 'vue'
import store from '../store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
//主页
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
//首页
import Index from '../views/Home/Index/index.vue'
//用户
import User from '../views/Home/User/recruit/user.vue'
//目录管理
import Cataloglist from '../views/Home/Catalog/department/cataloglist.vue'
import CatalogInfo from '../views/Home/Catalog/department/catalogInfo.vue'
import Report from '../views/Home/404.vue'
import { constantRoutes, anyRoutes, allRoutes } from './routes'
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
})
Vue.use(VueRouter)

let routes = [...constantRoutes, ...anyRoutes, ...allRoutes]
const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  store.commit('changeRoute', to.path)
  let token = sessionStorage.getItem('token')
  //   let permission =(sessionStorage.getItem('permission') && JSON.parse(sessionStorage.getItem('permission'))) || ''
  let arr =
    (sessionStorage.getItem('permission') &&
      sessionStorage.getItem('permission').split(',')) ||
    ''
  let whiteList = [
    '/index',
    '/mine',
    '/login',
    '/adduserinfo',
    '/edituser',
    '/adduser',
    '/userlist',
    '/user',
  ]
  //console.log(arr);
  if (to.path === '/login') {
    // let info = []
    // router.addRoutes(info)
    return next()
  }
  if (!token) {
    next('/login')
  } else {
    //  console.log(to.path);
    if (!whiteList.includes(to.path) && !arr.includes(to.path)) {
      // console.log('没权限');
      Message({
        message: '您没有访问权限，请联系管理员',
        type: 'error',
      })
      return next(from.path)
    }
  }
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export default router
