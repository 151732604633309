import Vue from 'vue'
import Vuex from 'vuex'
import { getInfo } from '../utils/utils.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgUrl: 'https://erp.campgrounds.cn/api/fileupload/imageFile',
    // imgUrl: 'http://172.20.10.8/index.php/api/fileupload/imageFile',
    imgUrlUpload: 'https://erp.campgrounds.cn/api/fileupload/fileUpload',
    // imgUrlUpload: 'http://172.20.10.8/index.php/api/fileupload/fileUpload',
    history: [],
    info: {},
    isCollapse: false,
    routeLink: '',
    breadNav: [],
    room_table_data:[],
    institution_number:0,
    family_number:0,
    permission:[]
  },
  mutations: {
    setFamilyNumber(state,data){
        state.family_number = data
    },
    setInstitutionNumber(state,data){
        state.institution_number = data
    },
    setRoomTableData(state,data){
        state.room_table_data = data
    },
    setbreadNav(state, data) {
      state.breadNav = data
    },
    changeRoute(state, data) {
      state.routeLink = data
    },
    changeColl(state, data) {  
      state.isCollapse = data
    },
 
    getroute(state, data) {
      let flag = 1
      state.history.forEach(item => {
        if (item.router.path == data.path) {
         // console.log('已经有了');
          flag = 0
        }
      });
      if (!flag) {
        return
      }
      let name = getInfo(data.path);
      let info = { name: name, router: data }
      state.history.push(info)
    },
    setUserInfo(state, data) {
      state.info = data
    },
    setPermission(state,data){
        state.permission = data
    }
  },
  actions: {

  },
  modules: {
  }
})
