<!--  -->
<template>
  <div class="home">
    <div class="card-info">
      <div class="card">
        <avue-data-progress :option="option"></avue-data-progress>
        <avue-data-tabs
          :option="option3"
          style="margin-top: 30px"
        ></avue-data-tabs>
      </div>
      <div class="card-right">
        <avue-data-box :option="option2"></avue-data-box>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      option3: {
        data: [
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "分类统计",
            subtitle: "实时",
            count: 7993,
            allcount: 10222,
            text: "当前分类总记录数",
            color: "rgb(27, 201, 142)",
            key: "类",
          },
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "附件统计",
            subtitle: "实时",
            count: 3112,
            allcount: 10222,
            text: "当前上传的附件数",
            color: "rgb(230, 71, 88)",
            key: "附",
          },
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "文章统计",
            subtitle: "实时",
            count: 908,
            allcount: 10222,
            text: "评论次数",
            color: "rgb(178, 159, 255)",
            key: "评",
          },
        ],
      },
      option2: {
        span: 24,
        data: [
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "人事统计",
            count: 12332,
            icon: "el-icon-user-solid",
            color: "rgb(49, 180, 141)",
          },
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "数据展示",
            count: 33,
            icon: "el-icon-view",
            color: "rgb(56, 161, 242)",
          },
          {
            click: function (item) {
              alert(JSON.stringify(item));
            },
            title: "权限管理",
            count: 2223,
            icon: "el-icon-setting",
            color: "rgb(117, 56, 199)",
          },
        ],
      },
      option: {
        span: 8,
        data: [
          {
            click: (item) => {
              console.log(item);
              this.add();
            },
            title: "任务完成率",
            color: "rgb(178, 159, 255)",
            count: 32,
          },
          {
            click: function (item) {
              console.log(item);
            },
            title: "签到率",
            color: "rgb(230, 71, 88)",
            count: 32,
          },
          {
            click: function (item) {
              console.log(item);
            },
            title: "签到率",
            color: "rgb(230, 71, 88)",
            count: 32,
          },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.card {
  width: 70%;
  background: #fff;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.card-right {
  width: 30%;
  background: #fff;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.card-info {
  display: flex;
  justify-content: space-between;
}
</style>