<!--  -->
<template>
  <!-- 添加用户 -->
  <el-dialog
    top="15px"
    width="60%"
    :append-to-body="true"
    title="查看日志"
    :visible.sync="dialogLog.state"
    @close="userClose()"
    @open="userOpen()"
  >
    <el-table :data="tableData" style="width: 100%" height="530px">
      <el-table-column prop="founction_name" label="页面名称">
      </el-table-column>
      <el-table-column prop="edit_time" label="操作时间"> </el-table-column>
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <el-tag type="success" v-show="scope.row.state == 1">
            正常显示
          </el-tag>
          <el-tag type="danger" v-show="scope.row.state == 2">
            已被删除
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="info" label="操作详情"> </el-table-column>
      <!-- <el-table-column prop="info" label="操作">
        <template slot-scope="scope">
          <el-button type="warning" @click="goRoute(scope.row)" plain
            >跳转任务</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @current-change="pageChange"
      @size-change="sizeChange"
      :page-sizes="[7, 15, 20]"
      layout="total, sizes, prev, pager, next"
      :page-size="this.getInfo.page_num"
      background
      :total="exp.num"
    >
    </el-pagination>
    <!-- 功能栏 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogLog.state = false" size="small">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList } from '@/api/catalog/alllog.js'
export default {
  props: {
    dialogLog: Object,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        route: '',
        info: '',
        page_code: 1,
        page_num: 7,
      },
      tableData: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    goRoute(info) {
      // return;
      console.log(info)
      let token = window.sessionStorage.getItem('token')
      let url = ` http://192.168.1.57:8080/#/taskmine?id=${info.task_id}&name=测试名称&token=${token}`
      console.log(url)
      if (!info.task_id) {
        return
      }
      window.open(url, '_blank')
    },
    userOpen() {
      this.catalogue()
    },
    userClose() {
      console.log('关闭当前组件')
    },
    async catalogue() {
      console.log(this.getInfo)
      this.getInfo.route = this.$route.path
      console.log(this.getInfo)
      let info = await getList(this.getInfo)
      console.log(info)
      this.tableData = info.data.data
      if (this.tableData) {
        this.exp.count = info.data.exp.count
        this.exp.num = info.data.exp.num
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style home>
.nav-card {
  width: 100%;
  background: #fff;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>
