import { post } from '@/utils/request'

//员工列表
export function getUserlistInfo(data) {
    return post('admin/employee/list', data)
}
//岗位选择
export function getOptions() {
    return post('admin/post/listbox',)
}
//编辑岗位
export function getEditJust(data) {
    return post('admin/employee/post', data)
}

//-------添加人事信息
export function getRegion() {
    return post('admin/company/region', )
}
export function getEditHrInfo(data) {
    return post('admin/employee/editHrInfo', data)
}
// 获取人事详情
export function getHrInfo(data) {
    return post('admin/employee/hrInfo', data)
}
export function getHrAdd(data) {
    return post('admin/employee/hrAdd', data)
}
//用工形式
export function getSection(data) {
    return post('admin/contract/section',  data)
}
//-------添加员工信息
export function getEditInfo(data) {
    return post('admin/employee/editInfo', data)
}
//增加个人详情
export function getAddUserInfo(data) {
    return post('admin/employee/addUserInfo', data)
}
//获取个人详情
export function getAdminInfo(data) {
    return post('admin/employee/info', data)
}
//设置参数
export function company(data) {
    return post('admin/company/section', data)
}
export function Jobsite(data) {
    return post('"admin/Jobsite/edit', data)
}
export function companyEdit(data) {
    return post('admin/company/edit', data)
}
export function contractEdit(data) {
    return post('admin/contract/edit', data)
}
export function workshiftEdit(data) {
    return post('admin/workshift/edit', data)
}



export function JobsiteDel(data) {
    return post('admin/Jobsite/del', data)
}
export function companyDel(data) {
    return post('admin/company/del', data)
}
export function contractDel(data) {
    return post('admin/contract/del', data)
}
export function workshiftDel(data) {
    return post('admin/workshift/del', data)
}


export function JobsiteAdd(data) {
    return post('admin/Jobsite/add', data)
}
export function companyAdd(data) {
    return post('admin/company/add', data)
}
export function contractAdd(data) {
    return post('admin/contract/add', data)
}
export function workshiftAdd(data) {
    return post('admin/workshift/add', data)
}


export function JobsiteList(data) {
    return post('admin/Jobsite/List', data)
}
export function companyList(data) {
    return post('admin/company/List', data)
}
export function contractList(data) {
    return post('admin/contract/List', data)
}
export function workshiftList(data) {
    return post('admin/workshift/List', data)
}


export function jobsiteSection(data) {
    return post('admin/Jobsite/section', data)
}
//获取权限列表
export function getRightList(data) {
    return post('admin/merchant/rightList', data)
}
//修改权限列表
export function getEditRight(data) {
    return post('admin/employee/right', data)
}
// 修改员工状态
export function changeEmp(data) {
    return post('admin/employee/updateEmployeeStatus', data)
}