<!--  -->
<template>
  <div class="home">
    <transition
      :duration="{ enter: 5000, leave: 10000 }"
      enter-active-class="animated  bounceInDown"
    >
      <el-container class="home-info" v-show="flag">
        <!-- 左边导航栏 -->
        <el-aside
          class="aside"
          :style="
            isCollapse
              ? 'width:64px;overflow:hidden;'
              : 'width:260px;overflow:hidden;'
          "
        >
          <div class="logo">
            <div>
              <img v-show="!isCollapse" src="../../assets/bund-3.png" alt="" />
            </div>
            <span v-show="!isCollapse" style="margin-left: 8px;"
              >浦江工业 | BUND Industries ™</span
            >
            <br />
            <span
              v-show="!isCollapse"
              style="margin-top: 33px;display: block;font-size: 26px;"
              >企业资源管理系统</span
            >
            <span v-show="isCollapse">ERP</span>
          </div>
          <!-- 导航 -->
          <div class="left-menu">
            <el-menu
              :collapse-transition="true"
              :collapse="isCollapse"
              :default-active="activerouter"
              class="el-menu-vertical-demo"
              background-color="#20222A"
              text-color="#A1A1A5"
              active-text-color="#FFFFFF"
              router
              :unique-opened="false"
              menu-trigger="click"
            >
              <NavMenu
                :menuList="navigationData"
                :isCollapse="isCollapse"
              ></NavMenu>
            </el-menu>
          </div>
        </el-aside>
        <!-- 内容 -->
        <el-container class="home-info">
          <!-- <div class="task" @click="gotest">
            <i class="el-icon-notebook-2"></i>
          </div> -->
          <el-header
            class="header-title"
            :style="{ height: headerFlag ? '43px' : 0 }"
          >
            <div class="info-left">
              <div style="width: 50px" @click="changeIsCollapse">
                <i class="el-icon-s-unfold" v-if="isCollapse"></i>
                <i class="el-icon-s-fold" v-else></i>
              </div>
              <Breadnav></Breadnav>
            </div>
            <div class="info-right">
              <div></div>
              <div class="icon-click">
                <div>
                  <el-popover width="400" trigger="click">
                    <el-tabs v-model="activeName">
                      <el-tab-pane label="消息" name="first">
                        <div class="first-info" v-if="taskTable.length">
                          <div
                            class="first"
                            v-for="item in taskTable"
                            :key="item.id"
                            @click="goMine"
                          >
                            <div class="first-title">{{ item.name }}</div>
                            <div class="first-name">
                              <el-tag size="medium">{{ item.u_name }}</el-tag>
                            </div>
                          </div>
                        </div>
                        <div v-if="taskTable.length == 0">暂无消息</div>
                      </el-tab-pane>
                      <el-tab-pane label="通知" name="second"
                        >暂无通知</el-tab-pane
                      >
                      <el-tab-pane label="任务" name="third"
                        >暂无任务</el-tab-pane
                      >
                    </el-tabs>

                    <div slot="reference" @click="taskList">
                      <i class="el-icon-bell"></i>
                      <div class="red-bro"></div>
                    </div>
                  </el-popover>
                </div>
                <div @click="allwindows">
                  <i class="el-icon-full-screen"></i>
                </div>
              </div>
              <div>
                <div class="title-info">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <img :src="img" alt="" width="30" class="image" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="del"
                        >退出登录</el-dropdown-item
                      >
                      <el-dropdown-item command="mine"
                        >个人中心</el-dropdown-item
                      >
                      <el-dropdown-item command="all"
                        >更多设置</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="item"
                        v-for="(item, index) in project"
                        :key="index"
                        >{{ item.name }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-header>
          <el-main class="info-main">
            <transition
              enter-active-class="animated fadeInDownBig"
              v-show="$route.meta.keepAlive"
            >
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
            </transition>
            <transition
              enter-active-class="animated fadeInDownBig"
              v-show="!$route.meta.keepAlive"
            >
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </transition>
          </el-main>
        </el-container>
      </el-container>
    </transition>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import NavMenu from '@/components/NavMenu'
import Breadnav from '@/components/Breadnav'
import { getNavList } from '@/api/home'
import { getUserInfo } from '@/api/mine'
import defaultImg from '../../assets/defaultStaffPhoto.png'
// import { allRoutes } from '../../router/routes'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { NavMenu, Breadnav },
  data() {
    //这里存放数据
    return {
      headerFlag: true,
      flag: false,
      token: window.sessionStorage.getItem('token'),
      //   img: window.sessionStorage.getItem('img'),
      taskTable: [],
      fullscreen: false,
      activeName: 'first',
      color: '#545C64',
      color2: '#F8F8FF',
      name: window.sessionStorage.getItem('name'),
      isicon: [],
      opened: true,
      navigationData: JSON.parse(
        window.sessionStorage.getItem('navigationData')
      ),
      project: [
        { name: '电商系统', url: 'http://sysapp.campgrounds.cn' },
        { name: 'Teambition', url: 'https://www.teambition.com/todo' },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {
    img() {
      if (window.sessionStorage.getItem('img') !== 'undefined') {
        //   console.log('can get img')
        return window.sessionStorage.getItem('img')
      } else {
        console.log('cannot get img')
        return defaultImg
      }
    },
    activerouter() {
      //   console.log(this.$route.path)
      return this.$route.path
    },
    isCollapse() {
      // console.log(1)
      return this.$store.state.isCollapse
    },
  },
  //监控data中的数据变化

  watch: {
    $route(val, old) {
      // 当前路由
      console.log('父组件路由切换')
    },
  },
  //方法集合
  methods: {
    goHeader() {
      this.headerFlag = !this.headerFlag
    },
    async taskList() {
      let info = await this.$axios.post('admin/inform/list')
      console.log(info)
      this.taskTable = info.data.data
    },
    //切换菜单栏
    changeIsCollapse() {
      console.log(this.isCollapse)
      let info = !this.isCollapse
      this.$store.commit('changeColl', info)
    },
    goMine() {
      this.$router.push('/mine')
    },
    gotest() {
      if (this.$route.path == '/taskall') {
        return
      }
      this.$router.push('/taskall')
    },
    //全屏事件
    allwindows() {
      let element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    goIndex() {
      if (this.$route.path == '/index') {
        return
      }
      this.$router.push('/index')
    },
    goRoute(url) {
      console.log(url)
      window.open(url, '_blank')
    },
    handleCommand(command) {
      console.log(command)
      if (command == 'del') {
        this.del()
      } else if (command == 'mine') {
        this.$router.push(
          '/mine',
          () => {},
          () => {}
        )
      } else if (command == 'all') {
        this.$router.push('/all')
      } else {
        console.log(command)
        this.goRoute(command.url)
      }
    },
    add() {
      let data = [
        {
          path: '*',
          component: () => import('@/views/Home/404.vue'),
        },
      ]
      this.$router.addRoutes(data)
    },
    //退出登录
    del() {
      window.sessionStorage.clear()
      this.$message({
        message: '已退出登录',
        type: 'warning',
      })
      this.$router.push('/login')
    },
    async getNavList() {
      let info = await getNavList()
      var seeform = JSON.stringify(info.data.data)
      window.sessionStorage.setItem('navigationData', seeform)
      let navigationData = window.sessionStorage.getItem('navigationData')
      // this.$store.commit('setNavigationData', JSON.parse(navigationData))

      this.navigationData = JSON.parse(navigationData)
      let res = this.getAllRightsRoute(this.navigationData)
      this.$store.commit('setPermission', res)
      window.sessionStorage.setItem('permission', res)
    },
    getAllRightsRoute(routes) {
      let res = []
      routes.forEach((item) => {
        if (item.path) {
          res.push(item.path)
        }
        if (item.son) {
          res = [...this.getAllRightsRoute(item.son), ...res]
        }
      })

      return res
    },
    async setKey() {
      let that = this
      document.onkeydown = function(e) {
        if (e.key == '`' && e.ctrlKey == true) {
          that.changeIsCollapse()
        }
      }
    },
    updateImg() {
      this.$EventBus.$on('updateImg', async () => {
        let { data: userinfo } = await getUserInfo()
        console.log(userinfo)
        this.img = userinfo.data.img.url

        window.sessionStorage.setItem('img', userinfo.data.img.url)
      })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // console.log(allRoutes)
    this.setKey()
    this.getNavList()
    this.flag = true
    this.updateImg()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  overflow: hidden;
}

.el-dropdown-link {
  cursor: pointer;
  color: #606266;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.home-info {
  height: 100%;
  width: 100%;
}

.name {
  font-size: 14px;
}

.header-title {
  /* width: 100%; */
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #eee;
  transition: all 0.3s;
  border-radius: 5px;
  margin: 10px 10px 10px 10px;
  box-sizing: border-box;
}

.title-info {
  width: 100%;
  line-height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.title {
  font-weight: 400;
  height: 100%;
  width: 200px;
  text-align: center;
  background: #cccccc;
  position: absolute;
  top: -20;
  left: 0;
  z-index: 999;
}

.aside {
  overflow-x: hidden;
  background-color: #20222a;
  transition: all 0.3s;
}

.el-menu {
  border-right-width: 0;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 260px;
  /* overflow: hidden; */
}

.aside-title {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
}

.el-main {
  padding: 0;
}

.info-main {
  padding: 0px 10px 10px 10px;
  position: relative;
  border-radius: 15px;
}

.image {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
  position: relative;
  z-index: 999;
  top: 10px;
  background: #f2f2f2;
}

.info-right {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-right > div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-click > div {
  height: 100%;
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.info-left {
  width: 70%;
  display: flex;
  align-items: center;
}

.info-left > div {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.el-menu-item.is-active {
  background-color: rgba(0, 0, 5, 0.8) !important;
}

.logo {
  width: 100%;
  height: 140px;
  line-height: 20px;
  padding: 20px 0;
  background-color: #20222a;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  color: #fff;
  text-align: center;
  border-bottom: 2px solid #1f2129;
}

.logo img {
  width: 235px;
}

.left-menu {
  height: calc(100% - 140px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.el-icon-s-unfold,
.el-icon-s-fold {
  font-size: 30px;
}

.goroute {
  padding-right: 30px;
  cursor: pointer;
}

.el-icon-bell {
  cursor: pointer;
}

.icon-click > div {
  position: relative;
}

.red-bro {
  position: absolute;
  top: 10px;
  right: 6px;
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}

.task {
  position: fixed;
  top: 50%;
  right: -2px;
  z-index: 999;
  color: #fff;
  background-color: #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #409eff;
  box-sizing: border-box;
  outline: 0;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.task2 {
  transition: all 0.3s;
  height: 34px;
}

.first-info {
  height: 200px;
  overflow: auto;
}

.first {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.first-title {
  width: 300px;
  display: flex;
  align-items: center;
}

.first-name {
  flex: 1;
  display: flex;
  align-items: center;
}

.el-card >>> {
  border-radius: 15px !important;
}

.el-checkbox >>> {
  zoom: 150% !important;
}

>>> .el-table {
  font-size: 16px !important;
}

>>> .el-form {
  font-size: 16px !important;
}

>>> .el-form-item {
  font-size: 16px !important;
}

>>> .el-form-item__label {
  font-size: 16px !important;
}
</style>
