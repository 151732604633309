import { post } from '@/utils/request'

export function getEdit(data) {
    return post('admin/department/edit', data)
}
export function getDel2(data) {
    return post('admin/department/del', data)
}
export function getAdd(data) {
    return post('admin/department/add', data)
}
export function getOption(data) {
    return post('admin/department/option', data)
}
export function getLeft() {
    return post('admin/department/left')
}
export function getIndex(data) {
    return post('admin/department/index', data)
}
export function getEditShow(data) {
    return post('admin/department/editShow', data)
}