<template>
  <div class="menu-bug-wrap">
    <div v-for="menu in menuList" :key="menu.id">
      <!-- 如果当前有子菜单，则显示 el-submenu ，在el-subment 里调用 递归组件 -->
      <el-submenu
        v-if="menu.son"
        :index="'rou' + menu.id"
        :key="menu.id"
        @mouseenter.stop="enter"
        :unique-opened="false"
      >
        <template slot="title">
          <!-- <i :class="menu.icon" v-if="menu.icon"></i>
        <i class="el-icon-menu" v-else></i> -->
          <span
            class="icon-lev"
            :style="{
              backgroundColor: setBgColor(menu.level),
              color: setTextColor(menu.level),
            }"
            @click.stop="changeColl"
          >
            {{ menu.level }}</span
          >
          <span :style="isCollapse ? 'visibility:hidden' : ''">
            {{ menu.name }}
          </span>
        </template>
        <!-- 调用自身  此处是重点-->
        <MenuTree :menuList="menu.son"></MenuTree>
      </el-submenu>
      <!-- 如果没有子菜单，则显示当前内容 -->
      <el-menu-item v-else :index="menu.path" :key="menu.id">
        <!-- <i :class="menu.icon" v-if="menu.icon"></i>
        <i class="el-icon-menu" v-else></i> -->
        <span
          class="icon-lev"
          :style="{
            backgroundColor: setBgColor(menu.level),
            color: setTextColor(menu.level),
          }"
          @click.stop="changeColl"
          >{{ menu.level }}</span
        >
        <span class="sub-text" :style="isCollapse ? 'visibility:hidden' : ''">
          {{ menu.name }}
        </span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuTree',
  props: {
    menuList: {
      type: Array,
      required: false,
    },
    isCollapse: false,
  },

  methods: {
    enter() {
      return
    },
    changeColl() {
      let info = !this.isCollapse
      this.$store.commit('changeColl', info)
    },
  },
  computed: {
    setBgColor() {
      return function(val) {
        let bgColorStr = ''
        switch (val) {
          case 1:
            bgColorStr = '#fff'
            break
          case 2:
            bgColorStr = '#939393'
            break
          case 3:
            bgColorStr = '#88c1f6'

            break
          case 4:
            bgColorStr = '#3679c0'
            break
          case 5:
            bgColorStr = '#f0592a'
            break
          case 6:
            bgColorStr = '#e61e07'
            break
          case 7:
            bgColorStr = '#3facfe'
            break
          case 8:
            bgColorStr = '#0690ff'
            break
          case 9:
            bgColorStr = '#fdcfcf'
            break
          case 10:
            bgColorStr = '#fb7171'
            break
          case 11:
            bgColorStr = '#fa4647'
            break
          case 12:
            bgColorStr = '#fa4647'
            break
          case 13:
            bgColorStr = '#f50504'
            break
          default:
            bgColorStr = '#000000'
        }
        return bgColorStr
      }
    },
    setTextColor() {
      return function(val) {
        let textColorStr = ''
        switch (val) {
          case 1:
            textColorStr = '#5f6368'
            break
          case 2:
            textColorStr = '#fff'
            break
          case 3:
            textColorStr = '#eee'
            break
          case 4:
            textColorStr = '#e6e6e6'
            break
          case 5:
            textColorStr = '#e3e3e3'
            break
          case 6:
            textColorStr = '#fff'
            break
          case 7:
            textColorStr = '#fff'
            break
          case 8:
            textColorStr = '#fff'
            break
          case 9:
            textColorStr = '#fff'
            break
          case 10:
            textColorStr = '#fdcfcf'
            break
          case 11:
            textColorStr = '#fb7171'
            break
          case 12:
            textColorStr = '#fa4647'
            break
          case 13:
            textColorStr = '#f50504'
            break
          default:
            textColorStr = '#000000'
        }
        return textColorStr
      }
    },
  },
}
</script>

<style scoped>
/* :style="isCollapse ? 'display:none' : ''" */

.el-menu--popup-right-start >>> {
  display: none !important;
}
.el-menu--collapse
  > .menu-bug-wrap
  >>> div
  > .el-submenu
  > .el-submenu__title
  > .el-submenu__icon-arrow {
  display: none;
}
.menu-bug-wrap {
}
.icon-lev {
  /* background-color: #ecf5ff; */
  font-weight: 900;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0px 8px;
  line-height: 22px;
  font-size: 13px;
  /* color: #409eff; */
  border-width: 1px;
  border-style: solid;
  border-radius: 14px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
}
.menu-bug-wrap >>> .el-submenu__title {
  font-size: 18px !important;
  font-weight: 700;
}
.menu-bug-wrap .sub-text {
  font-size: 18px !important;
  font-weight: 700;
}
</style>
