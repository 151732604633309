<!--  -->
<template>
  <div class="user">
    <!-- 添加用户 -->
    <el-dialog
      title="添加员工"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <el-form :model="form">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="userAddSubmit(form)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 绑定项目 -->
    <el-dialog
      title="绑定项目"
      :visible.sync="dialogFormMerchant"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <el-checkbox
        :true-label="1"
        :false-label="0"
        v-model="item.state"
        v-for="(item, index) in checkedList"
        :key="index"
        >{{ item.name }}</el-checkbox
      >
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormMerchant = false">取 消</el-button>
        <el-button type="primary" @click="setMerchant(form)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑岗位 -->
    <el-dialog
      title="编辑岗位"
      :visible.sync="dialogFormVisible5"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <el-select
        v-model="form.position"
        multiple
        style="width: 100%"
        placeholder="请选择"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible5 = false">取 消</el-button>
        <el-button type="primary" @click="setEditJust()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 收藏列表 -->
    <el-dialog
      title="收藏列表"
      :visible.sync="dialogFormVisible2"
      :append-to-body="true"
    >
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible2 = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog
      title="删除用户"
      :visible.sync="dialogVisible3"
      width="30%"
      :append-to-body="true"
    >
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible3 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <all-log :dialogLog="dialogLog" />
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input
            placeholder="请输入内容"
            v-model="getInfo.info"
            clearable
            @clear="search(getInfo.info)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search(getInfo.info)"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="goindex">添加新员工</el-button>
          <el-button type="primary" @click="gopush()">设置参数</el-button>
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-tag
              style="margin-right: 10px; margin-top: 5px"
              v-for="(item, index) in props.row.p_name"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="state" label="头像" width="120">
          <template slot-scope="scope">
            <el-avatar v-if="scope.row.img" :src="scope.row.img"></el-avatar>
            <el-avatar v-else :src="defaultImg"></el-avatar>
          </template>
        </el-table-column>

        <el-table-column prop="username" label="用户名" width="120">
        </el-table-column>
        <el-table-column prop="state" label="状态" width="180">
          <template slot-scope="scope">
            <el-switch
              @change="changeState(scope.row)"
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
              active-text="在职"
              inactive-text="离职"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="入职时间" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              style="margin-right: 10px"
              size="mini"
              type="primary"
              icon="el-icon-s-tools"
              @click="editJus(scope.row, form)"
            ></el-button>
            <!-- @click="handleEdit(scope.row)" -->
            <el-dropdown trigger="click" @command="handleCommand">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="composeValue(scope.row, 'type1')"
                  >人事信息</el-dropdown-item
                >
                <el-dropdown-item :command="composeValue(scope.row, 'type2')"
                  >个人信息</el-dropdown-item
                >
                <el-dropdown-item :command="composeValue(scope.row, 'type2')"
                  >修改密码</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="primary"
              icon="el-icon-s-data"
              @click="addMerchant(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        :pager-count="15"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import defaultImg from '../../../../assets/defaultStaffPhoto.png'

import {
  getUserlistInfo,
  getOptions,
  getEditJust,
  getRightList,
  getEditRight,
  changeEmp,
} from '@/api/user'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Breadcrumb,
  },
  data() {
    //这里存放数据
    return {
      defaultImg,
      uidform: {
        uid: '',
        merchant_id: [],
      },
      checkedList: [],
      dialogFormMerchant: false,
      dialogLog: {
        state: false,
      },
      options: [],
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //导航
      nav: [],
      getNav: [],
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        username: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--收藏
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      dialogFormVisible5: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async changeState(row) {
      console.log(row)
      const { data: res } = await changeEmp({ employee_id: row.id })
      console.log(res)
      if (res.code === 1) {
        this.$message.success('编辑成功')
      } else {
        this.$message.error(res.message)
      }
      this.getUserlist()
    },
    async addMerchant(item) {
      let info = await getRightList({ uid: item.id })
      this.uidform.uid = item.id
      this.checkedList = info.data.data
      this.dialogFormMerchant = true
    },
    async setMerchant(item) {
      let list = []
      this.checkedList.forEach((item) => {
        if (item.state == 1) {
          list.push(item.id)
        }
      })
      this.uidform.merchant_id = list
      let info = await getEditRight(this.uidform)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible5 = false
        this.getUserlist()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.getUserlist()
      this.dialogFormMerchant = false
    },

    dialogOpen() {
      this.dialogLog.state = true
    },
    handleCommand(command) {
      if (command.command == 'type1') {
        this.handleEdit({ id: command.info.id })
      } else if (command.command == 'type2') {
        this.handleEdit2({ id: command.info.id })
      }
    },
    composeValue(row, command) {
      return {
        info: row,
        command: command,
      }
    },
    goAdduser() {
      this.$router.push({
        path: '/adduser',
        name: '添加员工',
      })
    },
    //编辑岗位
    editJus(info) {
      this.form = info
      this.dialogFormVisible5 = true
      console.log('岗位')
    },
    async setEditJust() {
      let form = { id: this.form.id, post: this.form.position }
      let info = await getEditJust(form)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.dialogFormVisible5 = false
        this.getUserlist()
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.getUserlist()
      this.dialogFormVisible5 = false
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.getUserlist()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.getUserlist()
    },
    handleEdit(info) {
      this.$router.push({
        path: '/editUser',
        query: {
          id: info.id,
          name: '员工人事详情',
        },
      })
    },
    handleEdit2(info) {
      this.$router.push({
        path: '/adduserinfo',
        query: {
          id: info.id,
          name: '员工个人详情',
        },
      })
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    //传参
    handleCollect(row, form) {
      this.dialogVisible2 = true
      this.userdelinfo = row
    },
    gopush() {
      this.$router.push({
        path: '/allocation',
        query: {
          name: '设置参数',
        },
      })
    },
    goindex() {
      this.$router.push({
        path: '/adduser',
        query: {
          id: 'add',
          name: '添加员工',
        },
      })
    },
    //搜索事件
    search(e) {
      console.log('搜索信息： ' + e)
      this.getUserlist()
    },
    //添加事件--提交事件
    async userAddSubmit() {
      let info = await this.$axios.post('admin/employee/addUser', this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.dialogFormVisible = false
        this.getUserlist()
      } else {
        this.$message({
          showClose: true,
          message: '添加失败',
          type: 'error',
        })
        return
      }
      this.getUserlist()
      this.dialogFormVisible = false
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
      }
      console.log('close')
    },
    async getUserlist() {
      let info = await getUserlistInfo(this.getInfo)
      let info2 = await getOptions()
      this.options = info2.data.data
      console.log(info)
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getUserlist()
    // this.getFavorite();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.page {
  margin-top: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand >>> .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand >>> .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
