<template>
  <div class="user">
    <!-- 添加用户 -->
    <el-dialog
      v-dialogDrag
      title="新增菜单"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序" :label-width="formLabelWidth">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" :label-width="formLabelWidth">
          <el-input v-model="form.path"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="userAddSubmit(form)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改用户 -->
    <el-dialog
      v-dialogDrag
      title="修改菜单名称"
      :visible.sync="dialogFormVisible2"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="上级菜单" :label-width="formLabelWidth">
          <el-cascader
            :options="options"
            :props="optionProps"
            v-model="form.hierarchy"
            @change="handleChange"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" :label-width="formLabelWidth">
          <el-input v-model="form.path"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.order" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog title="删除菜单" :visible.sync="dialogVisible3" width="30%">
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="getDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <all-log :dialogLog="dialogLog" />
    <el-card>
      <div class="cata-menu-box">
        <!-- 头 -->
        <h2>ERP职能菜单表</h2>
        <div class="box-bd">
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input
                size="medium"
                placeholder="请输入内容"
                v-model="inputSearch"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search(inputSearch)"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button
                type="primary"
                size="medium"
                @click="dialogFormVisible = true"
                >添加菜单</el-button
              >
              <el-button type="success" size="medium" @click="goTree"
                >转为树状图</el-button
              >
              <el-button type="warning" size="medium" @click="dialogOpen"
                >查看日志</el-button
              >
            </el-col>
            <el-col :span="4"> </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="pid" label="上级名称"></el-table-column>
            <el-table-column prop="icon" label="图标" width="80">
              <template slot-scope="scope">
                <i :class="scope.row.icon"></i>
              </template>
            </el-table-column>
            <el-table-column
              prop="level"
              width="80"
              label="层级"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.level == 1" type="danger">一级</el-tag>
                <el-tag v-if="scope.row.level == 2" type="warning">二级</el-tag>
                <el-tag v-if="scope.row.level == 3">三级</el-tag>
                <el-tag v-if="scope.row.level == 4" type="success">四级</el-tag>
                <el-tag v-if="scope.row.level == 5" type="info">五级</el-tag>
                <el-tag v-if="scope.row.level == 6" type="info">六级</el-tag>
                <el-tag v-if="scope.row.level == 7" type="info">七级</el-tag>
                <el-tag v-if="scope.row.level == 8" type="info">八级</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="path"
              label="绑定路由"
              width="150"
            ></el-table-column>
            <el-table-column label="显示" width="100">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState2(scope.row.show, scope.row.id)"
                  v-model="scope.row.show"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="菜单状态" width="100">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState(scope.row.state, scope.row.id)"
                  v-model="scope.row.state"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-plus"
                  @click="pAdd(scope.row)"
                  >增加</el-button
                >
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20, 50]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :pager-count="15"
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getEdit,
  getDel2,
  getAdd,
  getIndex,
  getLeft,
  getEditShow,
} from '@/api/catalog/department'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Breadcrumb },
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 50,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      tableLoading: false,
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        parent_level: 1,
        icon: '',
        path: '',
        s_id: '',
        order: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true
    },
    goTree() {
      this.$router.push('/departmentinfo')
    },
    handleChange(info) {
      console.log(this.options)
    },
    async changeState(state, id) {
      let info = await getDel2({ id: id })
      this.judge(info)
    }, //修改状态
    async changeState2(state, id) {
      let info = await getEditShow({ id: id })
      this.judge(info)
    },
    //父级增加
    pAdd(info) {
      this.dialogFormVisible = true
      this.form.pid = info.id
    },
    //详情
    detail(value, a) {
      console.log(value)
      console.log(a)
      let number = value
      console.log(number)
      this.goEditgoods(number)
    },
    //详情事件
    goEditgoods(info) {
      this.$router.push({
        path: '/catalogInfo',
        query: {
          id: info.id,
          name: info.name,
        },
      })
    },
    dx() {
      this.form.icon = ''
      this.form.path = ''
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(row) {
      this.dialogFormVisible2 = true
      console.log(this.form)
      this.form = row
    },
    //编辑
    async edit() {
      let info = await getEdit(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.dialogFormVisible2 = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    async getDel(info2) {
      let info = await getDel2(info2)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.dialogVisible3 = false
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    //添加事件--提交事件
    async userAddSubmit(e) {
      console.log(e)
      let info = await getAdd(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '添加失败',
          type: 'error',
        })
        return
      }
      this.dialogFormVisible = false
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        parent_level: 1,
        icon: '',
        path: '',
        s_id: '',
        order: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.tableLoading = true
      let info = await getIndex(this.getInfo)
      let info2 = await getLeft()
      this.options = info2.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
      this.tableLoading = false
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.cata-menu-box {
  border-radius: 25px;
  box-shadow: -1px 3px 3px 3px #e6e6e6;
  overflow: hidden;
}
.cata-menu-box h2 {
  text-align: center;
  font-weight: 400;
  color: black;
  background: #ddd;
  height: 50px;
  margin: 0;
  line-height: 50px;
}
.cata-menu-box .box-bd {
  padding: 20px;
  box-sizing: border-box;
}
</style>
