<!--  -->
<template>
  <div class="index">
    <div style="height: 20px"></div>
    <Title></Title>
    <!-- <HomeInfo></HomeInfo> -->
    <HomeTask></HomeTask>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Title from "@/components/Title.vue";
import HomeInfo from "@/components/HomeInfo.vue";
import HomeTask from "@/components/HomeTask.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Title,
    HomeInfo,
    HomeTask,
  },
  data() {
    //这里存放数据
    return {
      getNav: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    info: function () {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.state.history;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    add() {
      this.$store.commit("getroute", "231312");
      console.log(this.$store.state.history);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.index {
  width: 100%;
  height: 100%;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>