<template>
  <div class="user">
    <!-- 添加用户 -->
    <el-dialog
      v-dialogDrag
      title="新增菜单"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序" :label-width="formLabelWidth">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" :label-width="formLabelWidth">
          <el-input v-model="form.path"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="userAddSubmit(form)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改用户 -->
    <el-dialog
      v-dialogDrag
      title="修改菜单名称"
      :visible.sync="dialogFormVisible2"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="上级菜单" :label-width="formLabelWidth">
          <el-cascader
            :options="options"
            :props="optionProps"
            v-model="form.hierarchy"
            @change="handleChange"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" :label-width="formLabelWidth">
          <el-input v-model="form.path"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.order" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog title="删除菜单" :visible.sync="dialogVisible3" width="30%">
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="getDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->

    <all-log :dialogLog="dialogLog" />
    <el-card>
      <div class="menu-box">
        <h2>ERP职能菜单</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input placeholder="请输入内容" v-model="inputSearch">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search(inputSearch)"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="16">
              <el-button type="primary" @click="dialogFormVisible = true"
                >添加菜单</el-button
              >
              <el-button type="success" @click="goTree">转为列表</el-button>
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 重构树状图表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="treelist"
            show-checkbox
            :expand-on-click-node="false"
            row-key="id"
            :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="职能菜单名称">
              <template slot-scope="scope">
                <span>
                  <i :class="scope.row.icon"></i> {{ scope.row.label }}
                  {{ scope.row.name }} {{ scope.row.level }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="职能菜单状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.show"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @click.native="changeOpen(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column width="260" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-plus"
                  @click="pAdd(scope.row)"
                  >增加</el-button
                >

                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 优化前的表格 -->
      <!-- <el-tree
        style="width: 600px; margin-top: 10px"
        :data="treelist"
        node-key="id"
        ref="tree"
        highlight-current
        :props="defaultProps"
        :expand-on-click-node="false"
        :default-expanded-keys="openList"
        @node-expand="expandNode"
        @node-collapse="collapseNode"
      >
        <span
          class="custom-tree-node"
          slot-scope="{ node, data }"
          :style="{ background: node.level % 2 == 0 ? '#ebeef5' : '' }"
        >
          <span :style="{ color: data.state == 2 ? 'red' : '' }">
            <i :class="data.icon"></i> {{ node.label }} {{ node.level }}</span
          >
          <span>
            <el-switch
              :width="30"
              style="margin-right: 20px"
              @change="changeState2(node, data.id)"
              v-model="data.show"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              :disabled="showState ? true : false"
            >
            </el-switch>
            <el-button type="text" size="mini" @click="pAdd(node, data)">
              增加
            </el-button>
            <el-button type="text" size="mini" @click="handleEdit(node, data)">
              编辑
            </el-button>
            <el-button type="text" size="mini" @click="remove(node, data)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree> -->
    </el-card>
    <!-- 更改状态提示框 -->
    <el-dialog
      :visible.sync="changeDialogVisible"
      :before-close="handleChangeClose"
      width="30%"
      title="提示"
    >
      <div class="content">
        <div class="warning-icon" />
        <span class="text">{{ warningText }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            changeDialogVisible = false
            catalogue()
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="confirmChangeState"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getEdit,
  getDel2,
  getAdd,
  getOption,
  getLeft,
  getEditShow,
} from '@/api/catalog/department'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Breadcrumb },
  data() {
    //这里存放数据
    return {
      tableLoading: false,
      openList: [],
      dialogLog: {
        state: false,
      },
      showState: false,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        parent_level: 1,
        icon: '',
        path: '',
        s_id: '',
        order: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      treelist: [],
      defaultProps: {
        children: 'son',
        label: 'name',
      },
      warningText: '',
      changeDialogVisible: false,
      row: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 状态修改
    async changeOpen(row) {
      console.log(row)
      this.row = row
      this.changeDialogVisible = true
      if (row.state === 0) {
        // 即将禁用
        this.warningText =
          '此操作将隐藏该职能菜单, 禁用所有功能不可用，并计入操作日志，是否继续?'
      } else if (row.state === 1) {
        // 即将开启
        this.warningText =
          '此操作将显示该职能菜单,启用后开启所有功能，并计入操作日志，是否继续?'
      }
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.catalogue()
          done()
        })
        .catch((_) => {})
    },
    async confirmChangeState() {
      const res = await getEditShow({ id: this.row.id })
      console.log(res)
      this.judge(res)
      // this.$message.success(res.message)

      this.changeDialogVisible = false
      this.catalogue()
    },
    loadNode(node, resolve) {
      console.log(node)
      // if (node.level === 0) {
      //   return resolve([{ name: "region1" }, { name: "region2" }]);
      // }
      if (node.level > 3) return resolve([])

      var hasChild = true
      // if (node.data.name === "region1") {
      //   hasChild = true;
      // } else if (node.data.name === "region2") {
      //   hasChild = false;
      // } else {
      //   hasChild = Math.random() > 0.5;
      // }
      let data = [
        {
          name: 'zone',
        },
        {
          name: 'zone',
        },
      ]
      resolve(data)
      // setTimeout(() => {
      //   var data;
      //   if (hasChild) {
      //     data = [
      //       {
      //         name: "zone" + this.count++,
      //       },
      //       {
      //         name: "zone" + this.count++,
      //       },
      //     ];
      //   } else {
      //     data = [];
      //   }

      //   resolve(data);
      // }, 500);
    },

    // 保存节点
    expandNode(data) {
      let flag = false
      this.openList.some((item) => {
        if (item === data.id) {
          flag = true
          return true
        }
      })
      if (!flag) {
        this.openList.push(data.id)
      }
    },
    // 删除节点
    collapseNode(data) {
      this.openList.some((item, i) => {
        if (item === data.id) {
          this.openList.length = i
        }
      })
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    async changeState2(state, id) {
      this.showState = true
      let info = await getEditShow({ id: id })
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    goTree() {
      this.$router.push('/department')
    },
    handleDragStart(node1) {
      console.log('drag start', node1)
    },
    handleChange(info) {
      console.log(this.options)
    },
    //修改状态
    changeState(state, id) {
      this.getDel({
        id: id,
      })
    },
    //父级增加
    pAdd(data) {
      console.log(data)
      this.dialogFormVisible = true
      this.form.pid = data.id
    },
    //详情
    detail(value, a) {
      console.log(value)
      console.log(a)
      let number = value
      console.log(number)
      this.goEditgoods(number)
    },
    //详情事件
    goEditgoods(info) {
      this.$router.push({
        path: '/catalogInfo',
        query: {
          id: info.id,
          name: info.name,
        },
      })
    },
    dx() {
      this.form.icon = ''
      this.form.path = ''
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(data) {
      this.dialogFormVisible2 = true
      this.form = data
    },
    //删除
    remove(data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.getDel({ id: data.id, state: true })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //编辑
    async edit() {
      let info = await getEdit(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.dialogFormVisible2 = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: '修改失败',
          type: 'error',
        })
        return
      }
      this.catalogue()
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    async getDel(info2) {
      let info = await getDel2(info2)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      this.dialogVisible3 = false
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async userAddSubmit(e) {
      let info = await getAdd(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.message,
          type: 'error',
        })
        return
      }
      this.dialogFormVisible = false
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        parent_level: 1,
        icon: '',
        path: '',
        s_id: '',
        order: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.tableLoading = true
      this.getNav = this.$getNavn('/department')
      let info = await getOption()
      let info2 = await getLeft()
      this.options = info.data.data
      this.treelist = info2.data.data
      this.showState = false
      this.tableLoading = false
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-right: none;
  border-top: none;
  padding-left: 20px;
}
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../../assets/exclamationmark.png') no-repeat;
}
</style>
