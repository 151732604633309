<!--  -->
<template>
  <!-- 面包屑导航 -->
  <div class="home">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="nav-card"
      v-if="!info"
    >
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in getNav" :key="index">{{
        item.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="nav-card"
      v-if="info"
    >
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(item, index) in getNav"
        :key="index"
        :to="{ path: item.path }"
        >{{ item.name }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ this.info }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {
    getNav: Array,
    info: String,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style home>
.nav-card {
  width: 100%;
  background: #fff;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>