import { post } from '@/utils/request'

export function getInform(data) {
    return post('admin/inform/inform', data)
}

export function getUppass(data) {
    return post('admin/employee/uppass', data)
}
export function getInfo(data) {
    return post('admin/inform/info', data)
}
//设置员工头像
export function getHeadPortrait(data) {
    return post('admin/employee/headPortrait', data)
}
//个人信息
export function getUserInfo(data) {
    return post('admin/user/userInfo', data)
}

// 修改密码

export function changePw(data) {
    return post('admin/employee/uppass', data)
}