import axios from 'axios';//引入axios
// 引入NProgress
import NProgress from 'nprogress'
// 引入nprogress样式文件
import'nprogress/nprogress.css'
import {
  Message,
} from 'element-ui'
import router from '../router'
import store from "../store/index";

let roupath = store.state
 axios.defaults.baseURL = 'https://erp.campgrounds.cn/'
// axios.defaults.baseURL = 'http://192.168.8.12/index.php/'
//  axios.defaults.baseURL = 'https://picnicpack.campgrounds.cn/index.php/'

// axios.defaults.baseURL = 'http://192.168.1.66:8081/'
// axios.defaults.timeout = 5000;//加载不出来5秒之后就是加载失败
axios.interceptors.request.use(config => {
    NProgress.start()
  if (!config.url.includes('login')) {  
    // console.log(window.sessionStorage.getItem("token"));
    config.headers['Accept-Token'] = window.sessionStorage.getItem("token")
    config.headers['Accept-Route'] = roupath.routeLink
  } return config
})
// 响应
axios.interceptors.response.use((response) => {
  if (response.data.code == 10001) {
    console.log("您的token已失效");
    Message({
      message: '您的token已失效',
      type: 'error',
    })
    window.sessionStorage.clear();
    router.push({ path: '/login' })

  }
  NProgress.done()
  return response

}, error => {
  // 在此定义请求错误需要做什么
  Message({
    message: '服务器错误',
    type: 'error',
  })

  return Promise.reject(error)
})

// 使用promise返回axios请求
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err.data)
    })

  })
}