<!--  -->
<template>
  <div class="home">
    <!-- 项目 -->
    <div class="info">
      <div class="info-left">
        <img width="72" :src="userImg" />
        <div class="info-left-info">
          <div class="info-left-top title">
            {{ name }}，欢迎使用 浦江工业 | BUND Industries ™ 企业资源管理系统
            <!-- <img src="../assets/defaultStaffPhoto.png" alt=""> -->
          </div>
          <!-- <div class="info-left-bom title-info">您是本公司XXXXXX</div> -->
        </div>
      </div>
      <!-- 项目数 -->
      <!-- <div class="info-right">
        <div class="info-right-text">
          <div class="info-right-top right-title">项目数</div>
          <div class="info-right-bom">00</div>
        </div>
        <div class="info-right-text">
          <div class="info-right-top right-title">项目数</div>
          <div class="info-right-bom">00</div>
        </div>
        <div class="info-right-text">
          <div class="info-right-top right-title">项目数</div>
          <div class="info-right-bom">00</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import defaultImg from '../assets/defaultStaffPhoto.png'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},

  data() {
    //这里存放数据
    return {
      name: window.sessionStorage.getItem('name'),
      defaultImg: '../assets/defaultStaffPhoto.png',
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    }
  },
  //监听属性 类似于data概念
  computed: {
    userImg() {
      console.log(window.sessionStorage.getItem('img') == 'undefined')

      if (window.sessionStorage.getItem('img') !== 'undefined') {
        //   console.log('can get img')
        return window.sessionStorage.getItem('img')
      } else {
        console.log('cannot get img')
        return defaultImg
      }
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.info {
  display: flex;
  align-items: center;
  padding: 25px 40px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  margin-right: -7px;
  margin-left: 0px;
  border-radius: 5px;
}
.info-left {
  flex: 7;
}
.info-right {
  flex: 3;
}
.info-left,
.info-right {
  display: flex;
}
.info-left-info,
.info-right-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}
.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.title-info {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.right-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}
.info-right-bom {
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  line-height: 38px;
  margin: 0;
}
.info-right-text {
  padding-right: 20px;
  border-right: 1px solid #ccc;
}
</style>
