
//主页
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
//首页
import Index from '../views/Home/Index/index.vue'
//用户
import User from '../views/Home/User/recruit/user.vue'
//目录管理
import Cataloglist from '../views/Home/Catalog/department/cataloglist.vue'
import CatalogInfo from '../views/Home/Catalog/department/catalogInfo.vue'
import Report from '../views/Home/404.vue'
//静态路由
export const constantRoutes = [
    {
        path: '/',
        redirect: '/home'
      },
      {
        //登录
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/home',
        redirect: '/index',
        component: Home,
        children: [
          {
            path: '/index',
            component: Index,
          }
        ]
      },
]
// 任意路由
export const anyRoutes = [
 
      // 404页面
      {
        path: "*",
        component: Report
      }
]
// 权限动态路由
export const allRoutes = [
  
  //目录管理
  {
    path: '/list',
    redirect: '/departmentinfo',
    component: Home,
    children: [
      {
        //目录管理
        path: '/departmentinfo',
        component: CatalogInfo
      },
      {
        //目录详情
        path: '/department',
        component: Cataloglist
      }, 
      {
        path: '/role',
        component: () => import('../views/Home/Catalog/role/role.vue')
      }, 
      {
        path: '/taskmenu',
        component: () => import("@/views/Home/Catalog/taskmenu/taskmenu.vue"),
      },
    ]
  },
  //人事模块
  {
    path: '/user',
    redirect: '/userlist',
    component: Home,
    children: [
      {
        path: '/adduser',
        component: () => import("@/views/Home/User/recruit/addUser.vue"),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/edituser',
        component: () => import("@/views/Home/User/recruit/editUser.vue"),
      },
      {
        path: '/adduserinfo',
        component: () => import("@/views/Home/User/recruit/addUserInfo.vue"),
      },
      {
        //用户列表
        path: '/userlist',
        component: User,
      },
    ]
  },
  // 进销存
  {
    path: '/psi',
    redirect: '/recipe',
    component: Home,
    children: [
      //原料管理
      {
        path: '/ingredient',
        component: () => import("@/views/Home/Psi/ingredient/ingredient.vue"),
      },
      //原料分类管理
      {
        path: '/ingredient/class',
        component: () => import("@/views/Home/Psi/ingredient/ingredientClass.vue"),
      },
      //产品列表
      {
        path: '/product',
        component: () => import("@/views/Home/Psi/product/product.vue"),
      },
      {
        //产品详情
        path: '/product/info',
        component: () => import("@/views/Home/Psi/product/InfoRecipe.vue"),
      },
      {
        //商品分类管理
        path: '/psiclassify',
        component: () => import("@/views/Home/Psi/psiclassify/psiclassify.vue"),
      },
      //结算方式
      {
        path: '/dealerpid',
        component: () => import("@/views/Home/Psi/dealer/dealerpid.vue"),
      },
      {
        //供应商列表
        path: '/dealer',
        component: () => import("@/views/Home/Psi/dealer/dealer.vue"),
      },
      {
        //添加供应商
        path: '/adddealer',
        component: () => import("@/views/Home/Psi/dealer/adddealer.vue"),
      },
      {
        //修改供应商
        path: '/editdealer',
        component: () => import("@/views/Home/Psi/dealer/editdealer.vue"),
      },
      {
        path: '/warehouse',
        component: () => import("@/views/Home/Psi/warehouse/warehouse.vue"),
      },
      {
        path: '/warehouse/storage',
        component: () => import("@/views/Home/Psi/warehouse/storage.vue"),
      },
      //商品
      {
        path: '/commodity',
        component: () => import("@/views/Home/Psi/commodity/commodity.vue"),
      },
      {
        path: '/commoditymodel',
        component: () => import("@/views/Home/Psi/commodity/commoditymodel/commoditymodel.vue"),
      },
      {
        path: '/addcommodity',
        component: () => import("@/views/Home/Psi/commodity/addcommodity.vue"),
      },
      {
        path: '/editcommodity',
        component: () => import("@/views/Home/Psi/commodity/editcommodity.vue"),
      },
      {
        path: '/commoditycate',
        component: () => import("@/views/Home/Psi/commodity/commoditycate/commoditycate.vue"),
      },
      {
        path: '/accountingmethods',
        component: () => import("@/views/Home/Psi/commodity/accountingmethods/accountingmethods.vue"),
      },
      //配件
      {
        path: '/accessory',
        component: () => import("@/views/Home/Psi/commodity/accessory/accessory.vue"),
      },
      {
        path: '/editaccessory',
        component: () => import("@/views/Home/Psi/commodity/accessory/editaccessory.vue"),
      },
      {
        path: '/addaccessory',
        component: () => import("@/views/Home/Psi/commodity/accessory/addaccessory.vue"),
      },
      //保养
      {
        path: '/service',
        component: () => import("@/views/Home/Psi/service/service.vue"),
      },
      {
        path: '/editservice',
        component: () => import("@/views/Home/Psi/service/editservice.vue"),
      },
      {
        path: '/addservice',
        component: () => import("@/views/Home/Psi/service/addservice.vue"),
      },
      //出入库管理
      {
        path: '/storage',
        component: () => import("@/views/Home/Psi/storageAll/storagelist.vue"),
      },
      // 入库审批管理
      {
        path: '/warehousingapproval',
        component: () => import("@/views/Home/Psi/storageAll/whApproval/whApproval.vue"),
      },
      {
        path: '/editstorage',
        component: () => import("@/views/Home/Psi/storageAll/editStoragelist.vue"),
      },
      {
        path: '/addstorage',
        component: () => import("@/views/Home/Psi/storageAll/addStoragelist.vue"),
      },
      // 商品入库
      {
        path: '/addToStorage',
        component: () => import("@/views/Home/Psi/storageAll/addToStorage.vue"),
      },
      // 单位管理
      {
        path: '/unit',
        component: () => import("@/views/Home/Psi/storageAll/unit.vue"),
      },
      //出库申请
      {
        path: '/outboundapply',
        component: () => import("@/views/Home/Psi/outbound/outboundapply.vue"),
      },
      //出库审批管理
      {
        path: '/outboundapprove',
        component: () => import("@/views/Home/Psi/outbound/outboundapprove.vue"),
      },
      //库存变动单据
      {
        path: '/storagechange',
        component: () => import("@/views/Home/Psi/inventory/storagechange.vue"),
      },

      //新增盘点
      {
        path: '/addcheck',
        component: () => import("@/views/Home/Psi/inventorycheck/addcheck.vue"),
      },
      //盘点记录
      {
        path: '/checkrecord',
        component: () => import("@/views/Home/Psi/inventorycheck/checkrecord.vue"),
      },
      //盘点分析
      {
        path: '/checkanalyse',
        component: () => import("@/views/Home/Psi/inventorycheck/checkanalyse.vue"),
      },
    // 库存总表
    {
        path: '/addInventory',
        component: () => import("@/views/Home/Psi/inventory/addInventory.vue"),
      },
      // 租赁
      {
        path: '/rental',
        component: () => import("@/views/Home/Psi/rental/rental.vue"),
      },
      // 售卖
      {
        path: '/sell',
        component: () => import("@/views/Home/Psi/sell/sell.vue"),
      },
      // 归还
      {
        path: '/lend',
        component: () => import("@/views/Home/Psi/lend/lend.vue"),
      },
      // 记录
      {
        path: '/listlog',
        component: () => import("@/views/Home/Psi/log/listlog.vue"),
      },
      // 优惠券
      {
        path: '/coupon',
        component: () => import("@/views/Home/Psi/coupon/coupon.vue"),
      },
    ]
  },
  // 教务管理中心
  {
    path:"/edu",
    name:"edu",
    component: Home,
    children:[
        {
            // 服务类型管理
            path: '/servicetype',
        component: () => import("@/views/Home/Edu/ServiceType.vue"),
        },
        {
            // 物料管理
            path: '/supplies',
        component: () => import("@/views/Home/Edu/Supplies.vue"),
        },
        {
            // 物料分类管理
            path: '/suppliescate',
        component: () => import("@/views/Home/Edu/SuppliesCate.vue"),
        },
        {
            // 服务地点管理
            path: '/location',
        component: () => import("@/views/Home/Edu/Location.vue"),
        },
        {
            // 拍摄点位管理
            path: '/shootingspot',
        component: () => import("@/views/Home/Edu/ShootingSpot.vue"),
        },
        {
            // 教员类型管理
            path: '/guide',
        component: () => import("@/views/Home/Edu/Guide.vue"),
        },
        {
            // 服务项目管理
            path: '/subject',
        component: () => import("@/views/Home/Edu/Subject.vue"),
        },
        {
            // 外来课程管理
            path: '/othercourses',
        component: () => import("@/views/Home/Edu/OtherCourses.vue"),
        },
        {
            //站点管理
            path: '/site',
        component: () => import("@/views/Home/Edu/Site.vue"),
        },
        {
            //站点管理
            path: '/siteservice',
        component: () => import("@/views/Home/Edu/SiteService.vue"),
        },
        {
            //站点管理
            path: '/servicemodel',
        component: () => import("@/views/Home/Edu/ServiceModel.vue"),
        },
        {
            //站点服务类型管理
            path: '/siteservicetype',
        component: () => import("@/views/Home/Edu/SiteServiceType.vue"),
        },
        {
            //站点服务类型管理
            path: '/coursesapproval',
        component: () => import("@/views/Home/Edu/CoursesApproval.vue"),
        }
    ]
  },
  // 房态系统
  {
    path:"/roomstate",
    name:"roomstate",
    component: Home,
    children:[
        {
            // 测试
            path: '/testroom',
        component: () => import("@/views/Home/Room/test.vue"),
        },
        {
            //房间组管理
            path: '/roomgroup',
        component: () => import("@/views/Home/Room/RoomGroup.vue"),
        },
        {
            // 房态管理
            path: '/roomstatemanage',
        component: () => import("@/views/Home/Room/RoomStateManage.vue"),
        },
        {
            // 房间管理
            path: '/roommanage',
        component: () => import("@/views/Home/Room/RoomManage.vue"),
        },

        {
            // 房间模型管理
            path: '/roommodel',
        component: () => import("@/views/Home/Room/RoomModel.vue"),
        },
        {
            // 订单管理
            path: '/orderlist',
        component: () => import("@/views/Home/Room/OrderList.vue"),
        },
        {
            // 报表管理
            path: '/sheet',
        component: () => import("@/views/Home/Room/Sheet.vue"),
        },
        {
            //店铺管理
            path: '/store',
        component: () => import("@/views/Home/Room/Store.vue"),
        },
        {
            //保洁管理
            path: '/cleaning',
        component: () => import("@/views/Home/Room/Cleaning.vue"),
        },
        {
            //维修管理
            path: '/repair',
        component: () => import("@/views/Home/Room/Repair.vue"),
        },
        {
            //维修管理
            path: '/cleannrepair',
        component: () => import("@/views/Home/Room/CleanNRepair.vue"),
        },
      
    ]
  },
  // 任务模块
  {
    path: '/task',
    redirect: '/taskall',
    component: Home,
    children: [
      {
        path: '/mine',
        component: () => import("@/views/Home/Mine/mine.vue"),
      },
      {
        path: '/allocation',
        component: () => import("@/views/Home/User/recruit/allocation.vue"),
      },
      {
        path: '/taskmine',
        component: () => import("@/views/Home/task/taskmine.vue"),
      },
      {
        path: '/taskall',
        component: () => import("@/views/Home/task/taskall.vue"),
      },
      {
        path: '/taskstage',
        component: () => import("@/views/Home/task/taskstage.vue"),
      },
      {
        path: '/tasktime',
        component: () => import("@/views/Home/task/tasktime.vue"),
      },
    ]
  },
  {
    //会员管理
    path: '/member',
    name: 'memberlist',
    component: Home,
    children: [
      {
        path: '/memberlist',
        component: () => import("@/views/Home/Member/listMember.vue"),
      },
      {
        path: '/memberlevel',
        component: () => import("@/views/Home/Member/MemberLevel.vue"),
      },
      {
        path: '/memberdiscount',
        component: () => import("@/views/Home/Member/MemberDiscount.vue"),
      },
      {
        path: '/institution',
        component: () => import("@/views/Home/Member/Institution.vue"),
      },
      {
        path:'/institutionmanage',
        component:()=>import("@/views/Home/Member/InstitutionManage.vue")
      },
      {
        path:'/familymanage',
        component:()=>import("@/views/Home/Member/FamilyManage.vue")
      },
      {
        path:'/family',
        component:()=>import("@/views/Home/Member/Family.vue")
      },
    ]
  },
  {
    //项目管理
    path: '/merchant',
    name: 'merchantlist',
    component: Home,
    children: [
      {
        path: '/merchantlist',
        component: () => import("@/views/Home/Merchant/Merchantlist.vue"),
      }
    ]
  },
  {
    //合同管理
    path: '/contract',
    name: 'contractlist',
    component: Home,
    children: [
      {
        path: '/contractlist',
        component: () => import("@/views/Home/Contract/contractlist.vue"),
      },]

  },
  {
    //财务管理
    path: '/finance',
    redirect: '/finance/typelist',
    name: 'Finance',
    component: Home,
    children: [
      {
        path: '/finance/typelist',
        component: () => import("@/views/Home/Accounting/financeType/typelist.vue"),
      }, 
      {
        path: '/finance/paylist',
        component: () => import("@/views/Home/Accounting/financePay/paylist.vue"),
      },
         //我的申购审核列表
         {
          path: '/mysubscribe/list',
          component: () => import("@/views/Home/Accounting/mysubscribe/mysubscribeList.vue"),
        },
         //我的申购单详情
         {
          path: '/mysubscribe/detail',
          component: () => import("@/views/Home/Accounting/detailsubscribe/detailsubscribe.vue"),
        },
      //公共审核列表
        //公共被拒绝列表
        {
          path: '/mysubscribe/commonberejected',
          component: () => import("@/views/Home/Accounting/commonsubscribe/commonsubscribe.vue"),
        },
      
        //公共部门审批列表
        {
          path: '/mysubscribe/commondepartment',
          component: () => import("@/views/Home/Accounting/commonsubscribe/commondepartment.vue"),
        },
        //公共老板审批列表
        {
          path: '/mysubscribe/commonboss',
          component: () => import("@/views/Home/Accounting/commonsubscribe/commonboss.vue"),
        },
        //公共财务审批列表
        {
          path: '/mysubscribe/commonfinance',
          component: () => import("@/views/Home/Accounting/commonsubscribe/commonfinance.vue"),
        },
          //公共财务执行列表
          {
            path: '/mysubscribe/commonsubscribe',
            component: () => import("@/views/Home/Accounting/commonsubscribe/commonsubscribe.vue"),
          },
    ]

  },
 
]